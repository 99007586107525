import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { getDashboards } from "../../redux/dashboard/dashboardSlice";
import useForm from "../../hooks/useForm";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { getStockDetailsForTransfer } from "../../redux/product/productSlice";

const defaultForm = {
  sale_date_from: moment(new Date()).format("YYYY-MM-DD"),
  sale_date_to: moment(new Date()).format("YYYY-MM-DD"),
};

const TransferDialog = ({ open, onClose, transfers }) => {
  const { t } = useTranslation();
  
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{t("Transfer Details")}</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Date")}</TableCell>
                <TableCell>{t("Warehouse")}</TableCell>
                <TableCell>{t("Rack")}</TableCell>
                <TableCell>{t("Product")}</TableCell>
                <TableCell>{t("Quantity")}</TableCell>
                {/* <TableCell>{t("Weight")}</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {transfers && transfers.length > 0 && transfers.map((transfer, index) => (
                <TableRow key={index}>
                  <TableCell>{moment(transfer.created_date).format("DD-MMM-YYYY")}</TableCell>
                  <TableCell>{transfer.wirehouse_name}</TableCell>
                  <TableCell>{transfer.rack_name}</TableCell>
                  <TableCell>{transfer.product_name}</TableCell>
                  <TableCell>{transfer.total_quantity}</TableCell>
                  {/* <TableCell>{transfer.total_weight}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

const DashboardCard = ({ title, value, route, onTransferView }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMedium = useMediaQuery({ maxWidth: 1023 });

  const handleRouteClick = () => {
    if (route === 5 && title === "Total Transfers") {
      onTransferView();
    } else if (route === 1) {
      navigate("/purchases", { replace: true });
    } else if (route === 2) {
      navigate("/sells", { replace: true });
    } else if (route === 3) {
      navigate("/purchase-return", { replace: true });
    } else if (route === 4) {
      navigate("/sell-return", { replace: true });
    } else if (route === 5) {
      navigate("/products", { replace: true });
    }
  };

  return (
    <Card sx={{ margin: "10px", height: "130px" }}>
      <CardContent>
        <Typography
          variant="h5"
          component="div"
          sx={{ fontSize: isMedium ? "15px" : "20px", fontWeight: 500 }}
        >
          {t(title)}: {value}
        </Typography>
        <div className="mt-4">
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleRouteClick}
          >
            VIEW ALL
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};
const DashboardCardReturn = ({ title, value1, value2 }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMedium = useMediaQuery({ maxWidth: 1023 });

  const handleRouteClick = (route) => {
    if (route === 3) {
      navigate("/purchase-return", { replace: true });
    } else if (route === 4) {
      navigate("/sell-return", { replace: true });
    }
  };
  return (
    <Card sx={{ margin: "10px", height: "130px" }}>
      <CardContent>
        <Typography
          variant="h5"
          component="div"
          sx={{ fontSize: isMedium ? "15px" : "20px", fontWeight: 500 }}
        >
          {t(title)}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          Purchase Return: {value1}
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => handleRouteClick(3)}
          >
            VIEW ALL
          </Button>
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            gap: "20px",
            marginTop: "5px",
          }}
        >
          Sell Return: {value2}
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => handleRouteClick(4)}
          >
            VIEW ALL
          </Button>
        </Typography>
      </CardContent>
    </Card>
  );
};

const DashboardCardGraph = ({ title, data, dataKey, chartType }) => {
  const { t } = useTranslation();
  return (
    <div style={{ width: "100%", overflowX: "auto" }}>
      {/* Adjusted width and added overflow-x */}
      <Card sx={{ margin: "10px" }}>
        <CardContent>
          <Typography variant="h6" component="div">
            {t(title)}
          </Typography>
          {chartType === "bar" ? (
            <ResponsiveContainer width="100%" aspect={2 / 1}>
              <BarChart data={data}>
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend />
                <Bar dataKey={dataKey} fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <ResponsiveContainer width="100%" aspect={2 / 1}>
              <LineChart data={data}>
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey={dataKey} stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { form, resetForm, handleChange, setForm } = useForm(defaultForm);
  const dashboardData = useSelector((state) => state.dashboard.dashboards);
  const [transferDialogOpen, setTransferDialogOpen] = useState(false);
  const [transfers, setTransfers] = useState([]);

  useEffect(() => {
    dispatch(
      getDashboards({
        from: form.sale_date_from,
        to: form.sale_date_to,
      })
    );
  }, []);

  const handleView = (e) => {
    e.preventDefault();
    dispatch(
      getDashboards({
        from: form.sale_date_from,
        to: form.sale_date_to,
      })
    );
  };

  const handleTransferView = async () => {
    try {
      // Replace with your actual API call
      const response = await dispatch(getStockDetailsForTransfer({
        from_date: form.sale_date_from,
        to_date: form.sale_date_to
      })).unwrap();
      

      setTransfers(response.data?.rows || []);
      setTransferDialogOpen(true);
    } catch (error) {
      console.error('Error fetching transfers:', error);
      // Handle error (show toast message, etc.)
    }
  };

  return (
    <div className="p-4">
      <div className="w-full flex flex-col md:flex-row items-center gap-4">
        <div className="flex flex-col md:flex-row gap-3">
          <TextField
            label={t("Date From")}
            name="sale_date_from"
            type="date"
            value={
              form.sale_date_from &&
              moment(form.sale_date_from).format("YYYY-MM-DD")
            }
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label={t("Date To")}
            name="sale_date_to"
            type="date"
            value={
              form.sale_date_to &&
              moment(form.sale_date_to).format("YYYY-MM-DD")
            }
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
        </div>
        <div>
          <Button variant="contained" color="secondary" onClick={handleView}>
            {t("VIEW")}
          </Button>
        </div>
      </div>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={12} md={3}>
          <DashboardCard
            title={t("Total Purchase")}
            value={dashboardData && dashboardData.totalPurchase}
            route={1}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DashboardCard
            title={t("Total Sell")}
            value={dashboardData && dashboardData.totalSell}
            route={2}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DashboardCardReturn
            title={t("Total Returns")}
            value1={dashboardData && dashboardData.totalPurchaseReturn}
            value2={dashboardData && dashboardData.totalSellReturn}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DashboardCard
            title={t("Total Products")}
            value={dashboardData && dashboardData.totalProducts}
            route={5}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DashboardCard
            title={t("Total Transfers")}
            value={dashboardData && dashboardData.totalTransfer}
            route={5}
            onTransferView={handleTransferView}
          />
        </Grid>
      </Grid>

      <Typography
        sx={{
          paddingLeft: "10px",
          marginTop: "20px",
          fontWeight: "500",
          fontSize: "20px",
        }}
      >
        {t("Trends")}
      </Typography>
      <div className="w-full">
        <Grid
          container
          spacing={{ xs: 2, md: 4 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={12} md={6}>
            <DashboardCardGraph
              title="Total Purchase"
              data={dashboardData && dashboardData.purchaseList}
              dataKey="value"
              chartType="bar"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DashboardCardGraph
              title="Total Sale"
              data={dashboardData && dashboardData.saleList}
              dataKey="value"
              chartType="line"
            />
          </Grid>
        </Grid>
      </div>

      <TransferDialog 
        open={transferDialogOpen}
        onClose={() => setTransferDialogOpen(false)}
        transfers={transfers}
      />
    </div>
  );
};

export default Dashboard;

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useForm from "../../hooks/useForm";
import { getWirehouses } from "../../redux/wirehouse/wirehouseSlice";
import {
  getProduct,
  getProductsByNameOrCode,
  getStockData,
  getStockDetails,
} from "../../redux/product/productSlice";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const ViewTypeList = [
  { label: "Wirehouse Wise", value: 1 },
  { label: "Product Wise", value: 2 },
];
const defaultForm = {
  wirehouse_id: "",
  product_id: "",
  stock_view_type: 2,
};

const DetailsDialog = ({ open, handleClose, detailsData, type }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl" // Adjust the width as per your requirement
      PaperProps={{
        // Adjust the height as per your requirement
        style: { minHeight: "60vh", width: "80%" },
      }}
      sx={{ zIndex: 999999 }}
    >
      <DialogTitle>
        Stock Details For Product:{" "}
        {detailsData && detailsData.length > 0 && detailsData[0].product_name}
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Action Date</TableCell>
                <TableCell>Batch</TableCell>
                {type === 1 && <TableCell>Wirehouse</TableCell>}
                {type === 1 && <TableCell>Rack</TableCell>}
                <TableCell>Product Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Action form</TableCell>
                <TableCell>Quantity In</TableCell>
                <TableCell>Quantity Out</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {detailsData.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {moment(item.created_date).format("DD-MMM-YYYY hh:mm a")}
                  </TableCell>
                  <TableCell>{item.batch_number}</TableCell>
                  {type === 1 && <TableCell>{item.wirehouse_name}</TableCell>}
                  {type === 1 && <TableCell>{item.rack_name}</TableCell>}
                  <TableCell>{item.product_name}</TableCell>
                  <TableCell>{item.type}</TableCell>
                  <TableCell>
                    {item.reference_type === "purchase_master"
                      ? "purchase"
                      : item.reference_type === "purchase_return"
                      ? "purchase reutrn"
                      : item.reference_type === "sale_return"
                      ? "sell reutrn"
                      : item.reference_type === "sales_master"
                      ? "sell"
                      : "transfer"}
                  </TableCell>
                  <TableCell>{item.total_in}</TableCell>
                  <TableCell>{item.total_out}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ViewStock = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { form, resetForm, handleChange, setForm } = useForm(defaultForm);
  const [selectedOption, setSelectedOption] = useState(null);
  const wirehouseList = useSelector((state) => state.wirehouse.wirehouses);
  const options = useSelector((state) => state.product.searchedProducts);
  const stockData = useSelector((state) => state.product.stockData);

  const [openDialog, setOpenDialog] = useState(false);
  const [detailsData, setDetailsData] = useState([]);

  const id = location.state?.id;

  const handleDetailsClick = async (e, item, stock_view_type) => {
    e.preventDefault();
    const details = await dispatch(
      getStockDetails({
        product_id: item.product_id,
        wirehouse_id: item.wirehouse_id,
        stock_view_type,
      })
    );
    const data =
      (details &&
        details.payload &&
        details.payload.data &&
        details.payload.data.rows) ||
      [];
    setDetailsData(data);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(getWirehouses());
  }, []);


  useEffect(() => {
    if (id) { // Only fetch product if id exists
      dispatch(getProduct(id)).then((res) => {
        if (res?.payload?.data) {
          
          const obj = {
            wirehouse_id: res.payload.data.warehouse_id,
            product_id: res.payload.data.id,
            stock_view_type: 2,
          }
          dispatch(getStockData(obj));
        }
      });
    }
  }, [id]);

  const fetchData = async (searchTerm) => {
    if (searchTerm !== "") {
      setLoading(true);
      const response = await dispatch(
        getProductsByNameOrCode({ nameOrCode: searchTerm })
      );
      setLoading(false);
    }
  };
  const handleSelect = (e) => {
    const { name, value } = e.target;
    if (name === "wirehouse_id") {
      const updateForm = {
        ...form,
        wirehouse_id: value,
      };
      setForm({ ...updateForm });
    }
    if (name === "stock_view_type") {
      const updateForm = {
        ...form,
        stock_view_type: value,
        wirehouse_id: "",
      };
      setForm({ ...updateForm });
    }
  };
  const handleOptionChange = (event, newValue) => {
    const updateForm = {
      ...form,
      product_id: newValue ? newValue.id : "",
    };
    setForm({ ...updateForm });
    setSelectedOption(newValue);
  };

  const handleViewStock = () => {
    dispatch(getStockData(form));
  };

  return (
    <div className="p-4">
      <div className="w-full flex flex-col gap-5 items-start">
        <FormControl fullWidth margin="normal">
          <InputLabel>{t("View Type")}</InputLabel>
          <Select
            name="stock_view_type"
            label={t("View Type")}
            value={form.stock_view_type}
            onChange={handleSelect}
            error={form.stock_view_type === ""}
          >
            {ViewTypeList &&
              ViewTypeList.length > 0 &&
              ViewTypeList.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel>{t("Wirehouse")}</InputLabel>
          <Select
            name="wirehouse_id"
            label={t("Wirehouse")}
            value={form.wirehouse_id}
            onChange={handleSelect}
          >
            {wirehouseList &&
              wirehouseList.length > 0 &&
              wirehouseList.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Autocomplete
          freeSolo={true}
          fullWidth
          options={options}
          loading={loading}
          getOptionLabel={(option) =>
            option.product_name +
            " - " +
            option.product_code +
            " - " +
            option.weight
          }
          onInputChange={(event, newInputValue) => {
            fetchData(newInputValue);
          }}
          onChange={handleOptionChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Search Product")}
              fullWidth
              error={form.product_id === ""}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={handleViewStock}
          disabled={form && form.product_id === ""}
        >
          {t("VIEW STOCK")}
        </Button>

        {stockData.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {/* Define table header cells */}
                  {form.stock_view_type === 1 && (
                    <TableCell>Wirehouse</TableCell>
                  )}
                  {form.stock_view_type === 1 && <TableCell>Rack</TableCell>}
                  <TableCell>Product</TableCell>
                  <TableCell>Weight</TableCell>
                  {/* <TableCell>Batch No</TableCell> */}
                  <TableCell>Quantity</TableCell>
                  {/* <TableCell>Type</TableCell> */}
                  {/* Add more headers if needed */}
                  <TableCell>Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stockData.map((item, index) => (
                  <TableRow key={index}>
                    {/* Render table rows based on fetched data */}
                    {form.stock_view_type === 1 && (
                      <TableCell>{item.wirehouse_name || ""}</TableCell>
                    )}
                    {form.stock_view_type === 1 && (
                      <TableCell>{item.rack_name || ""}</TableCell>
                    )}
                    <TableCell style={{ width: "400px" }}>
                      {item.product_name || ""}
                    </TableCell>
                    <TableCell style={{ width: "400px" }}>
                      {item.weight || ""}
                    </TableCell>
                    {/* <TableCell>{item.batch_number || 0}</TableCell> */}
                    <TableCell>{item.total_quantity || 0}</TableCell>
                    {/* <TableCell>{item.type || ""}</TableCell> */}
                    {/* Add more cells if needed */}
                    <TableCell sx={{ width: "100px" }}>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={(e) =>
                          handleDetailsClick(e, item, form.stock_view_type)
                        }
                      >
                        Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="h4">{t("No Stock Found")}</Typography>
        )}
      </div>
      <DetailsDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        detailsData={detailsData}
        type={form.stock_view_type}
      />
    </div>
  );
};

// src/redux/store.js

import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import themeReducer from "./theme/themeSlice";
import productCategoryReducer from "./product-category/productCategorySlice";
import productSubCategoryReducer from "./product-subcategory/productSubCategorySlice";
import productUnitReducer from "./product-unit/productUnitSlice";
import productPriceLevelReducer from "./product-price-level/productPriceLevelSlice";
import productBrandReducer from "./product-brand/productBrandSlice";
import productSellingMethodReducer from "./product-selling-method/productSellingMethodSlice";
import productReducer from "./product/productSlice";
import barcodeSymbologyReducer from "./barcode-symbology/barcodeSymbologySlice";
import userReducer from "./user/userSlice";
import wirehouseReducer from "./wirehouse/wirehouseSlice";
import supplierReducer from "./supplier/supplierSlice";
import rackReducer from "./rack/rackSlice";
import purchaseStatusReducer from "./purchase-status/purchaseStatusSlice";
import purchaseReducer from "./purchase/purchaseSlice";
import paymentStatusReducer from "./payment-status/paymentStatusSlice";
import loadingReducer from "./loader/loadingSlice";
import purchaseReturnReducer from "./purchase-return/purchaseReturnSlice";
import sellReducer from "./sell/sellSlice";
import customerReducer from "./customer/customerSlice";
import sellReturnReducer from "./sell-return/sellReturnSlice";
import companyReducer from "./company/companySlice";
import barcodeScannerReducer from "./barcode-scan/barcodeScannerSlice";
import dashboardReducer from "./dashboard/dashboardSlice";
import menuReducer from "./menu/menuSlice";
import securityRuleReducer from "./security-rule/securityRuleSlice";
import securityGroupReducer from "./security-group/securityGroupSlice";
import productSizeReducer from "./product-size/productSizeSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    theme: themeReducer,
    productCategory: productCategoryReducer,
    productSubCategory: productSubCategoryReducer,
    productUnit: productUnitReducer,
    productPriceLevel: productPriceLevelReducer,
    productBrand: productBrandReducer,
    productSellingMethod: productSellingMethodReducer,
    product: productReducer,
    barcodeSymbology: barcodeSymbologyReducer,
    user: userReducer,
    wirehouse: wirehouseReducer,
    supplier: supplierReducer,
    rack: rackReducer,
    purchaseStatus: purchaseStatusReducer,
    purchase: purchaseReducer,
    paymentStatus: paymentStatusReducer,
    loading: loadingReducer,
    purchaseReturn: purchaseReturnReducer,
    sell: sellReducer,
    customer: customerReducer,
    sellReturn: sellReturnReducer,
    company: companyReducer,
    barcodeScanner: barcodeScannerReducer,
    dashboard: dashboardReducer,
    menu: menuReducer,

    securityRule: securityRuleReducer,
    securityGroup: securityGroupReducer,
    productSize: productSizeReducer,
  },
});
export default store;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import useForm from "../../../hooks/useForm";
import moment from "moment";
import { getRacksByWirehouseId } from "../../../redux/rack/rackSlice";
import { getPaymentStatuss } from "../../../redux/payment-status/paymentStatusSlice";
import { getWirehouses } from "../../../redux/wirehouse/wirehouseSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { savePurchase } from "../../../redux/purchase/purchaseSlice";
const { v4: uuidv4 } = require("uuid");

const defaultForm = {
  id: "",

  wirehouse_id: "",
  rack_id: "",
  purchase_date: moment(new Date()).format("YYYY-MM-DD"),
  batch_number: "",
  supplier_id: "",
  purchase_status_id: 1,
  total_tax_amount: 0,
  discount_amount: 0,
  delivery_cost: 0,
  total_amount: 0,
  purchase_note: "",
  purchase_by: "",
  payment_status_id: "305b18ca-6ba7-48e9-9dce-8a852fbb04e8",

  created_by: "",
  created_date: "",
  updated_by: "",
  updated_date: "",
  quantity: 1,
  unit_purchase_price: 0,
  Attachments: [],
  ItemDetails: [
    {
      id: "",
      purchase_id: "",
      product_id: "",
      purchase_unit_id: "",
      quantity: 0,
      unit_purchase_price: 0,
      discount_amount: 0,
      tax_amount: 0,
      sub_total: 0,
      rack_id: "",
    },
  ],
};
const purchaseStatusList = [
  { label: "Received", value: 1 },
  { label: "Partial", value: 2 },
  { label: "Pending", value: 3 },
  { label: "Ordered", value: 4 },
];
const QuickPurchaseDialog = ({ open, onClose, selectedProduct }) => {
  const dispatch = useDispatch();
  const { form, resetForm, handleChange, setForm } = useForm(defaultForm);
  const wirehouseList = useSelector((state) => state.wirehouse.wirehouses);
  const rackList = useSelector((state) => state.rack.racks);
  const [purchaseStatusId, setPurchaseStatusId] = useState("");
  const paymentStatusList = useSelector(
    (state) => state.paymentStatus.paymentStatuss
  );
  useEffect(() => {
    dispatch(getWirehouses());
    dispatch(getPaymentStatuss());
  }, [open]);

  const handleSubmit = async () => {
    try {
      const tmpTotal = form.ItemDetails.reduce(
        (acc, item) => parseFloat(acc) + parseFloat(item.sub_total),
        0
      );
      const updatedForm = {
        ...form,
        total_amount:
          parseFloat(tmpTotal) +
          parseFloat(form.delivery_cost) -
          parseFloat(form.discount_amount),
        total_tax_amount: form.ItemDetails.reduce(
          (acc, item) => parseFloat(acc) + parseFloat(item.tax_amount),
          0
        ),
        PurchaseChilds: [
          {
            product_id: selectedProduct.id,
            purchase_unit_id: selectedProduct.purchase_unit_id,
            rack_id: form.rack_id,
            quantity: form.quantity,
            unit_purchase_price: form.unit_purchase_price,
          },
        ],
      };
      const res = dispatch(savePurchase(updatedForm))
        .then((response) => {
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 201
          ) {
            toast.success(
              response && response.payload && response.payload.message
            );
            resetForm();
          }
        })
        .then(() => {
          onClose();
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          toast.error(error);
        });
    } catch (error) {
      console.error("Error submitting quick purchase:", error);
      toast.error("Failed to save quick purchase");
    }
  };

  const handleSelect = (e) => {
    const { name, value } = e.target;
    if (name === "wirehouse_id") {
      const updateForm = {
        ...form,
        [name]: value,
        rack_id: "",
      };
      setForm({ ...updateForm });
      dispatch(getRacksByWirehouseId(value));
    } else {
      const updateForm = {
        ...form,
        [name]: value,
      };
      setForm({ ...updateForm });
    }
  };

  const canBeSubmit = () => {
    if (form) {
      const isValid =
        form.wirehouse_id !== "" &&
        form.rack_id !== "" &&
        form.purchase_status_id !== "";
      return isValid;
    }
    return false;
  };
  return (
    <div>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>
          <p>Quick Purchase</p>
          <p className="text-red-600">
            {selectedProduct && selectedProduct.PurchaseStatus}
          </p>
        </DialogTitle>
        <DialogContent>
          <div className="flex flex-col">
            <div className="w-full flex flex-col md:flex-row items-center gap-4">
              <FormControl fullWidth margin="normal">
                <InputLabel>Wirehouse</InputLabel>
                <Select
                  name="wirehouse_id"
                  label="Wirehouse"
                  value={form.wirehouse_id}
                  onChange={handleSelect}
                  error={form.wirehouse_id === ""}
                >
                  {wirehouseList &&
                    wirehouseList.length > 0 &&
                    wirehouseList.map((item) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Rack</InputLabel>
                <Select
                  value={form.rack_id}
                  label="Rack"
                  name="rack_id"
                  onChange={handleSelect}
                  error={form.rack_id === ""}
                >
                  {rackList &&
                    rackList.length > 0 &&
                    rackList.map((item) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className="w-full flex flex-col md:flex-row items-center gap-4">
              <TextField
                label="Purchase Date"
                name="purchase_date"
                type="date"
                value={
                  form.purchase_date &&
                  moment(form.purchase_date).format("YYYY-MM-DD")
                }
                onChange={handleChange}
                required
                error={form.purchase_date === ""}
                fullWidth
                margin="normal"
              />
            </div>

            <div className="w-full flex flex-col md:flex-row items-center gap-4">
              <FormControl fullWidth margin="normal">
                <InputLabel>Purchase Status</InputLabel>
                <Select
                  name="purchase_status_id"
                  label="Purchase Status"
                  value={form.purchase_status_id}
                  onChange={handleSelect}
                  error={form.purchase_status_id === ""}
                >
                  {purchaseStatusList &&
                    purchaseStatusList.length > 0 &&
                    purchaseStatusList.map((item) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Payment Status</InputLabel>
                <Select
                  name="payment_status_id"
                  label="Payment Status"
                  value={form.payment_status_id}
                  onChange={handleSelect}
                  sx={{ zIndex: 999999 }}
                >
                  {paymentStatusList &&
                    paymentStatusList.length > 0 &&
                    paymentStatusList.map((item) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className="w-full flex flex-col md:flex-row items-center gap-4 mt-2">
              <TextField
                error={form.quantity <= 0}
                label="Quantity"
                type="number"
                id="quantity"
                name="quantity"
                value={form.quantity}
                onChange={handleChange}
                required
                fullWidth
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
              />
              <TextField
                label="Unit Price"
                type="number"
                id="unit_purchase_price"
                name="unit_purchase_price"
                value={form.unit_purchase_price}
                onChange={handleChange}
                fullWidth
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="secondary"
            disabled={!canBeSubmit()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default QuickPurchaseDialog;

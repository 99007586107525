import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useForm from "../../hooks/useForm";
import {
  getPurchase,
  getPurchaseByBatchNo,
  setSearchedPurchase,
} from "../../redux/purchase/purchaseSlice";
import _ from "../../util/lodash/lodash";
import { savePurchaseReturn } from "../../redux/purchase-return/purchaseReturnSlice";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const defaultForm = {
  batch_number: "",
  id: "",
  purchase_id: "",
  return_date: "",
  product_id: "",
  quantity: 0,
  amount: 0,
  return_note: "",
  purchase_item_id: "",
  created_by: "",
  created_date: "",
  created_ip: "",
  updated_by: "",
  updated_date: "",
  company_id: "",

  PurchaseReturnChilds: [
    {
      id: "",
      product_id: "",
      quantity: 0,
      amount: 0,
      purchase_item_id: "",
      returned_quantity: 0,
    },
  ],
  ItemDetails: [
    {
      id: 0,
      purchase_id: "",
      product_id: "",
      purchase_unit_id: "",
      quantity: 0,
      unit_purchase_price: 0,
      discount_amount: 0,
      tax_amount: 0,
      sub_total: 0,
      rack_id: "",
      returned_quantity: 0,
    },
  ],
  Attachments: [],
};

export const PurchaseReturn = (props) => {
  const location = useLocation();
  const { state } = location || {};
  const batch_number = state?.batch_number || "";
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { form, resetForm, handleChange, setForm } = useForm(defaultForm);

  const searchedPurchase = useSelector(
    (state) => state.purchase.searchedPurchase
  );
  useEffect(() => {
    if (state && batch_number !== "") {
      const updateForm = {
        ...form,
        batch_number: batch_number,
      };
      dispatch(getPurchaseByBatchNo(updateForm));
    } else {
      dispatch(setSearchedPurchase(null));
      setForm(defaultForm);
    }
  }, [batch_number, location]);
  useEffect(() => {
    if (searchedPurchase) {
      setForm({ ...searchedPurchase });
    }
  }, [searchedPurchase]);

  const handleSearchPurchase = () => {
    dispatch(getPurchaseByBatchNo(form));
  };

  const handleReturn = async (e, item) => {
    e.preventDefault();
    const updateForm = {
      ...form,
      PurchaseReturnChilds: [
        {
          product_id: item.product_id,
          quantity: item.quantity,
          returned_quantity: item.returned_quantity,
          amount: item.amount,
          purchase_item_id: item.id,
        },
      ],
      ItemDetails: form && form.ItemDetails,
    };
    try {
      const res = await dispatch(savePurchaseReturn(updateForm));
      if (res && res.payload && res.payload.statusCode === 201) {
        toast.success("Returned Successfully");
        dispatch(getPurchaseByBatchNo({ batch_number: form.batch_number }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleReturnQuantityChange = (e, index) => {
    const { name, value } = e.target;
    const updateForm = JSON.parse(JSON.stringify(form.ItemDetails));

    if (name === "returned_quantity") {
      if (
        Number(value) + Number(updateForm[index].total_out) >
        updateForm[index].quantity
      ) {
        updateForm[index].error =
          "Returned quantity cannot exceed purchased quantity";
      } else {
        updateForm[index].error = null;
      }
      updateForm[index].returned_quantity = Number(value);
    }

    setForm({ ...form, ItemDetails: updateForm });
  };

  return (
    <div className="p-4">
      <div className="w-full flex flex-col gap-5 items-start">
        <TextField
          label={t("Purchase No")}
          name="batch_number"
          value={form.batch_number}
          onChange={handleChange}
          required
          error={form.batch_number === ""}
          placeholder={t("Enter Batch No to Search")}
          fullWidth
          margin="normal"
        />

        <Button
          variant="contained"
          color="secondary"
          onClick={handleSearchPurchase}
          disabled={form && form.batch_number === ""}
        >
          {t("view purchase")}
        </Button>

        {form && form.id !== "" ? (
          <TableContainer component={Paper}>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  {/* Define table header cells */}
                  <TableCell>Batch</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell>Rack</TableCell>
                  <TableCell>Purchased Quantity</TableCell>
                  <TableCell>Return Quantity</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {form &&
                  form.ItemDetails &&
                  form.ItemDetails.map((item, index) => (
                    <TableRow key={index}>
                      {/* Render table rows based on fetched data */}
                      <TableCell>
                        {(searchedPurchase && searchedPurchase.batch_number) ||
                          ""}
                      </TableCell>
                      <TableCell>{item.product_name || ""}</TableCell>
                      <TableCell>{item.unit_name || ""}</TableCell>
                      <TableCell>{item.rack_name || 0}</TableCell>
                      <TableCell>{item.quantity || 0}</TableCell>
                      <TableCell sx={{ width: "250px" }}>
                        {item.total_out > 0 && (
                          <Typography variant="caption">
                            Already Returned Quantity: {item.total_out}
                          </Typography>
                        )}
                        <TextField
                          label="Return Quantity"
                          name="returned_quantity"
                          variant="standard"
                          type="number"
                          value={item.returned_quantity}
                          onChange={(e) => handleReturnQuantityChange(e, index)}
                          required
                          error={item.error || item.returned_quantity <= 0}
                          helperText={item.error}
                          placeholder="Enter return quantity"
                          fullWidth
                          margin="normal"
                        />
                      </TableCell>
                      <TableCell>
                        {item.can_return ? (
                          <Button
                            variant="contained"
                            size="small"
                            disabled={item.error || item.returned_quantity <= 0}
                            onClick={(e) => handleReturn(e, item)}
                          >
                            Return
                          </Button>
                        ) : (
                          <Typography variant="caption">
                            Already returned full quantity
                          </Typography>
                        )}
                      </TableCell>
                      {/* Add more cells if needed */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="h4">{t("No Purchase Found")}</Typography>
        )}
      </div>
    </div>
  );
};

// src/redux/reportSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeApiCall } from "../../makeAPICall";
import {
  CUSTOMER_REPORT_API,
  DAILY_SELL_REPORT_API,
  REPORT_CONTROLLER,
  SELL_REPORT_API,
  SELL_SUMMARY_REPORT_API,
  STOCK_SUMMARY_REPORT_API,STOCK_SUMMARY_REPORT_BY_WAREHOUSE_API
} from "../../actionTypes";
import StockSummaryReport from "../../page/reports/StockSummaryReport";

export const printSellSummaryReport = createAsyncThunk(
  "report/printSellSummaryReport",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${REPORT_CONTROLLER}/${SELL_SUMMARY_REPORT_API}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const printStockSummaryReport = createAsyncThunk(
  "report/printStockSummaryReport",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${REPORT_CONTROLLER}/${STOCK_SUMMARY_REPORT_API}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const printStockSummaryReportByWarehouse = createAsyncThunk(
  "report/printStockSummaryReportByWarehouse",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${REPORT_CONTROLLER}/${STOCK_SUMMARY_REPORT_BY_WAREHOUSE_API}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const printDailySellReport = createAsyncThunk(
  "report/printDailySellReport",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${REPORT_CONTROLLER}/${DAILY_SELL_REPORT_API}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const printSellReport = createAsyncThunk(
  "report/printSellReport",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${REPORT_CONTROLLER}/${SELL_REPORT_API}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const printCustomerReport = createAsyncThunk(
  "report/printCustomerReport",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${REPORT_CONTROLLER}/${CUSTOMER_REPORT_API}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

const reportSlice = createSlice({
  name: "report",
  initialState: {
    reports: [],
    sellSummaryReportData: null,
    stockSummaryReportData: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(printSellSummaryReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(printSellSummaryReport.fulfilled, (state, action) => {
        state.sellSummaryReportData = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(printSellSummaryReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(StockSummaryReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(StockSummaryReport.fulfilled, (state, action) => {
        state.stockSummaryReportData = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(StockSummaryReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {} = reportSlice.actions;
export default reportSlice.reducer;

// src/redux/purchaseSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeApiCall } from "../../makeAPICall";
import {
  ATTACHMENT_CONTROLLER,
  BATCH_BY_PRODUCT_RACK_API,
  GET_PURCHASE_BY_BATCH_NO_API,
  PURCHASE_CONTROLLER,
  PURCHASE_PAGINATION_CONTROLLER,
} from "../../actionTypes";

export const getPurchasesWithPagination = createAsyncThunk(
  "purchase/getPurchasesWithPagination",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PURCHASE_PAGINATION_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const savePurchase = createAsyncThunk(
  "purchase/savePurchase",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PURCHASE_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const updatePurchase = createAsyncThunk(
  "purchase/updatePurchase",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "put",
        `/${PURCHASE_CONTROLLER}/${obj.id}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getPurchases = createAsyncThunk(
  "purchase/getPurchases",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${PURCHASE_CONTROLLER}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const getPurchase = createAsyncThunk(
  "purchase/getPurchase",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${PURCHASE_CONTROLLER}/${id}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;
      if (data && data.data) {
        const attRes = await makeApiCall(
          "post",
          `/${ATTACHMENT_CONTROLLER}`,
          { table_name: "purchase_master", reference_id: id },
          {}
        );
        // Merge the data arrays from both responses
        if (attRes && attRes.data && attRes.data.data) {
          data.data.data.Attachments =
            attRes.data.data.length > 0
              ? attRes.data.data.map((x) => {
                  return {
                    ...x,
                    OriginalName: x.original_name,
                    FilePath: x.url,
                    Size: x.size,
                  };
                })
              : [];
        }
      }
      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const deletePurchase = createAsyncThunk(
  "purchase/deletePurchase",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "delete",
        `/${PURCHASE_CONTROLLER}/${id}`,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getPurchaseByBatchNo = createAsyncThunk(
  "purchase/getPurchaseByBatchNo",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PURCHASE_CONTROLLER}/${GET_PURCHASE_BY_BATCH_NO_API}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getBatchByProductAndRack = createAsyncThunk(
  "purchase/getBatchByProductAndRack",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PURCHASE_CONTROLLER}/${BATCH_BY_PRODUCT_RACK_API}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
const purchaseSlice = createSlice({
  name: "purchase",
  initialState: {
    purchases: [],
    searchedPurchase: null,
    batchList: [],
    loading: false,
    error: null,
  },
  reducers: {
    setSearchedPurchase: (state, action) => {
      state.searchedPurchase = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPurchasesWithPagination.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPurchasesWithPagination.fulfilled, (state, action) => {
        state.purchases = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getPurchasesWithPagination.rejected, (state, action) => {
        state.purchases = [];
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(savePurchase.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(savePurchase.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(savePurchase.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getPurchases.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPurchases.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.purchases =
          data &&
          data.length > 0 &&
          data.map((x) => {
            return {
              label: x.name,
              value: x.id,
            };
          });
        state.loading = false;
        state.error = null;
      })
      .addCase(getPurchases.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getPurchaseByBatchNo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPurchaseByBatchNo.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.searchedPurchase = data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getPurchaseByBatchNo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getBatchByProductAndRack.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBatchByProductAndRack.fulfilled, (state, action) => {
        const data = action.payload.data.rows || [];
        state.batchList =
          data &&
          data.length > 0 &&
          data.map((x) => {
            return {
              label: x.batch_no,
              value: x.batch_no,
            };
          });
        state.loading = false;
        state.error = null;
      })
      .addCase(getBatchByProductAndRack.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setSearchedPurchase } = purchaseSlice.actions;
export default purchaseSlice.reducer;

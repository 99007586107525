// StockSummaryPdf.js
import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import {
  PaidThroughList,
  ProductUnitEnum,
  SellStatusEnum,
} from "../../../../actionTypes";
import { Util } from "../../../../util/Util";
import {
  makeGramToVori,
  makeGramToVoriAmt,
} from "../../../purchase/create-purchase/ItemTable";
const util = new Util();

// Import fonts if needed
// Font.register({
//   family: "Open Sans",
//   fonts: [
//     { src: "path/to/OpenSans-Regular.ttf" },
//     { src: "path/to/OpenSans-Bold.ttf", fontWeight: "bold" },
//   ],
// });

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,
  },

  header: {
    marginBottom: 20,
    textAlign: "center",
    display: "flex",
    gap: 8,
    fontSize: 10,
  },
  tableHeader: {
    flexDirection: "row",
    borderBottomWidth: 0.5,
    borderBottomColor: "#000",
    alignItems: "flex-start",
    padding: 5,
  },
  tableCellHead: {
    padding: 5,
    fontSize: 8,
    fontWeight: "bold",
    width: "auto", // Adjust as needed
    textAlign: "left",
  },
  tableCell: {
    padding: 5,
    fontSize: 8,
    width: "auto", // Adjust as needed
  },
  table: {
    width: "100%", // Take up full width
  },
  footer: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
  },
});

const getCalculatedValue = (
  // msize,
  // unitName,
  // showUnit,
  selectedOption
) => {
  let val = "";

  if (selectedOption && selectedOption.unit_id === ProductUnitEnum.Vori) {
    let sonaton =
      (selectedOption.weight_in_vori || 0) +
      " vori," +
      (selectedOption.weight_in_ana || 0) +
      " ana," +
      (selectedOption.weight_in_roti || 0) +
      " roti," +
      (selectedOption.weight_in_point || 0) +
      " point.";

    val += sonaton;
    val += "/";
    val += util.convertToGram(
      selectedOption.weight_in_vori,
      selectedOption.weight_in_ana,
      selectedOption.weight_in_roti,
      selectedOption.weight_in_point
    );
    val += " grams.";
  } else if (
    selectedOption &&
    selectedOption.unit_id === ProductUnitEnum.Gram
  ) {
    val += makeGramToVori(selectedOption.measurement_size);
    val += "/";
    let digital = selectedOption.measurement_size + " grams.";
    val += digital;
  }
  // else {
  //   val += msize + " " + unitName;
  //   if (showUnit) val += ",";
  // }

  return val;
};

const calculateWeightDifference = (inList, outList) => {
  const totalIn = {
    vori: inList.reduce(
      (acc, item) => acc + parseFloat(item.total_weight_in_vori || 0),
      0
    ),
    ana: inList.reduce(
      (acc, item) => acc + parseFloat(item.total_weight_in_ana || 0),
      0
    ),
    roti: inList.reduce(
      (acc, item) => acc + parseFloat(item.total_weight_in_roti || 0),
      0
    ),
    point: inList.reduce(
      (acc, item) => acc + parseFloat(item.total_weight_in_point || 0),
      0
    ),
  };

  const totalOut = {
    vori: outList.reduce(
      (acc, item) => acc + parseFloat(item.total_weight_in_vori || 0),
      0
    ),
    ana: outList.reduce(
      (acc, item) => acc + parseFloat(item.total_weight_in_ana || 0),
      0
    ),
    roti: outList.reduce(
      (acc, item) => acc + parseFloat(item.total_weight_in_roti || 0),
      0
    ),
    point: outList.reduce(
      (acc, item) => acc + parseFloat(item.total_weight_in_point || 0),
      0
    ),
  };

  const difference = {
    vori: totalIn.vori - totalOut.vori,
    ana: totalIn.ana - totalOut.ana,
    roti: totalIn.roti - totalOut.roti,
    point: totalIn.point - totalOut.point,
  };

  // Normalize the results
  return util.normalizeWeight(
    difference.vori,
    difference.ana,
    difference.roti,
    difference.point
  );
};

const StockSummaryByWarehousePdf = ({ data, company, fromDate, toDate }) => {
  // Add null checks for data
  if (!data) {
    return (
      <Document>
        <Page style={styles.page} orientation="landscape">
          <View style={styles.header}>
            <Text>{company?.company_name || ""}</Text>
            <Text>{company?.address || ""}</Text>
            <Text>Phone: {company?.phone || ""}</Text>
          </View>
          <View style={styles.table}>
            <Text>No data available</Text>
          </View>
        </Page>
      </Document>
    );
  }

  // Ensure inList and outList exist with default empty arrays
  const inList = data?.inList || [];
  const outList = data?.outList || [];

  const weightDifference = calculateWeightDifference(inList, outList);

  // Calculate Total In based on unit type with null checks
  const totalIn = {
    vori: inList.reduce((acc, current) => {
      if (!current) return acc;

      return acc + parseFloat(current.total_weight_in_vori || 0);
    }, 0),
    ana: inList.reduce((acc, current) => {
      if (!current) return acc;

      return acc + parseFloat(current.total_weight_in_ana || 0);
    }, 0),
    roti: inList.reduce((acc, current) => {
      return acc + parseFloat(current.total_weight_in_roti || 0);
    }, 0),
    point: inList.reduce((acc, current) => {
      return acc + parseFloat(current.total_weight_in_point || 0);
    }, 0),
  };

  const totalInNormalized = util.normalizeWeight(
    totalIn.vori || 0,
    totalIn.ana || 0,
    totalIn.roti || 0,
    totalIn.point || 0
  );

  // Calculate Total Out based on unit type with null checks
  const totalOut = {
    vori: outList.reduce((acc, current) => {
      if (!current) return acc;

      return acc + parseFloat(current.total_weight_in_vori || 0);
    }, 0),
    ana: outList.reduce((acc, current) => {
      if (!current) return acc;

      return acc + parseFloat(current.total_weight_in_ana || 0);
    }, 0),
    roti: outList.reduce((acc, current) => {
      if (!current) return acc;

      return acc + parseFloat(current.total_weight_in_roti || 0);
    }, 0),
    point: outList.reduce((acc, current) => {
      if (!current) return acc;

      return acc + parseFloat(current.total_weight_in_point || 0);
    }, 0),
  };

  const totalOutNormalized = util.normalizeWeight(
    totalOut.vori || 0,
    totalOut.ana || 0,
    totalOut.roti || 0,
    totalOut.point || 0
  );

  return (
    <Document>
      <Page style={styles.page} orientation="landscape">
        {/* Header with null checks */}
        <View style={styles.header}>
          <Text>{company?.company_name || ""}</Text>
          <Text>{company?.address || ""}</Text>
          <Text>Phone: {company?.phone || ""}</Text>
        </View>

        {/* Body */}
        <View style={styles.table}>
          {/* Table Headers */}
          <View style={styles.tableHeader}>
            <Text style={[styles.tableCellHead, { width: "20%" }]}>Date</Text>
            <Text style={[styles.tableCellHead, { width: "20%" }]}>
              Wirehouse
            </Text>
            {/* <Text style={[styles.tableCellHead, { width: "10%" }]}>Rack</Text>
            <Text style={[styles.tableCellHead, { width: "10%" }]}>Unit</Text> */}
            <Text style={[styles.tableCellHead, { width: "20%" }]}>
              Quantity
            </Text>
            <Text style={[styles.tableCellHead, { width: "40%" }]}>Weight</Text>
          </View>

          {/* Table Body with null checks */}
          {inList.map((row, index) => {
            if (!row) return null;

            return (
              <View key={index} style={styles.tableHeader}>
                <Text style={[styles.tableCell, { width: "20%" }]}>
                  {moment(fromDate).format("DD-MMM-YYYY")} -{" "}
                  {moment(toDate).format("DD-MMM-YYYY")}
                </Text>
                <Text style={[styles.tableCell, { width: "20%" }]}>
                  {row.wirehouse_name || ""}
                </Text>
                {/* <Text style={[styles.tableCell, { width: "10%" }]}>
                  {row.rack_name || ""}
                </Text>
                <Text style={[styles.tableCell, { width: "10%" }]}>
                  {row.unit_name || ""}
                </Text> */}
                <Text style={[styles.tableCell, { width: "20%" }]}>
                  {row.total_quantity || 0}
                </Text>
                <Text style={[styles.tableCell, { width: "40%" }]}>
                  {/* {getCalculatedValue({
                    unit_id: row.unit_id,
                    weight_in_vori: row.total_weight_in_vori || 0,
                    weight_in_ana: row.total_weight_in_ana || 0,
                    weight_in_roti: row.total_weight_in_roti || 0,
                    weight_in_point: row.total_weight_in_point || 0,
                  })} */}
                  {row.total_weight_in_vori || 0} vori,
                  {row.total_weight_in_ana || 0} ana,
                  {row.total_weight_in_roti || 0} roti,
                  {row.total_weight_in_point || 0} point.
                </Text>
              </View>
            );
          })}

          {/* Total In with null checks */}
          <View style={styles.tableHeader}>
            <Text style={[styles.tableCellHead, { width: "60%" }]}>
              Total In
            </Text>
            <Text style={[styles.tableCellHead, { width: "40%" }]}>
              {getCalculatedValue({
                unit_id: "4f7ca439-ff7e-467d-bd81-f37f0b12dcab",
                weight_in_vori: totalInNormalized?.vori || 0,
                weight_in_ana: totalInNormalized?.ana || 0,
                weight_in_roti: totalInNormalized?.roti || 0,
                weight_in_point: totalInNormalized?.point || 0,
              })}
            </Text>
          </View>
        </View>

        <View style={[styles.table, { marginTop: "40px" }]}>
          {/* Table Header */}
          <View style={styles.tableHeader}>
            <Text style={[styles.tableCellHead, { width: "20%" }]}>Date</Text>
            <Text style={[styles.tableCellHead, { width: "20%" }]}>
              Wirehouse
            </Text>
            {/* <Text style={[styles.tableCellHead, { width: "10%" }]}>Rack</Text>
            <Text style={[styles.tableCellHead, { width: "10%" }]}>Unit</Text> */}
            <Text style={[styles.tableCellHead, { width: "20%" }]}>
              Quantity
            </Text>
            <Text style={[styles.tableCellHead, { width: "40%" }]}>Weight</Text>
          </View>

          {/* Table Body */}
          {outList.map((row, index) => (
            <View key={index} style={styles.tableHeader}>
              <Text style={[styles.tableCell, { width: "20%" }]}>
                {moment(fromDate).format("DD-MMM-YYYY")} -{" "}
                {moment(toDate).format("DD-MMM-YYYY")}
              </Text>
              <Text style={[styles.tableCell, { width: "20%" }]}>
                {row.wirehouse_name || ""}
              </Text>
              {/* <Text style={[styles.tableCell, { width: "10%" }]}>
                {row.rack_name || ""}
              </Text>
              <Text style={[styles.tableCell, { width: "10%" }]}>
                {row.unit_name || ""}
              </Text> */}
              <Text style={[styles.tableCell, { width: "20%" }]}>
                {row.total_quantity || 0}
              </Text>
              <Text style={[styles.tableCell, { width: "40%" }]}>
                {/* {getCalculatedValue({
                  unit_id: row.unit_id,
                  weight_in_vori: row.weight_in_vori || 0,
                  weight_in_ana: row.weight_in_ana || 0,
                  weight_in_roti: row.weight_in_roti || 0,
                  weight_in_point: row.weight_in_point || 0,
                })} */}
                {row.total_weight_in_vori || 0} vori,
                {row.total_weight_in_ana || 0} ana,
                {row.total_weight_in_roti || 0} roti,
                {row.total_weight_in_point || 0} point.
              </Text>
            </View>
          ))}
          <View style={styles.tableHeader}>
            <Text style={[styles.tableCellHead, { width: "60%" }]}>
              Total Out
            </Text>
            <Text style={[styles.tableCellHead, { width: "40%" }]}>
              {getCalculatedValue({
                unit_id: "4f7ca439-ff7e-467d-bd81-f37f0b12dcab",
                weight_in_vori: totalOutNormalized.vori,
                weight_in_ana: totalOutNormalized.ana,
                weight_in_roti: totalOutNormalized.roti,
                weight_in_point: totalOutNormalized.point,
              })}
            </Text>
          </View>
          <View style={styles.tableHeader}>
            <Text style={[styles.tableCellHead, { width: "60%" }]}>
              In Stock
            </Text>
            <Text style={[styles.tableCellHead, { width: "40%" }]}>
              {/* {getCalculatedValue({
                unit_id: outList.length > 0 && outList[0].unit_id,
                weight_in_vori: weightDifference.vori,
                weight_in_ana: weightDifference.ana,
                weight_in_roti: weightDifference.roti,
                weight_in_point: weightDifference.point,
              })} */}
              {weightDifference.vori} vori,
              {weightDifference.ana} ana,
              {weightDifference.roti} roti,
              {weightDifference.point} point. /{" "}
              {util.convertToGram(
                weightDifference.vori,
                weightDifference.ana,
                weightDifference.roti,
                weightDifference.point
              )}
            </Text>
          </View>
        </View>

        {/* Footer */}
        <View
          style={styles.footer}
          render={({ pageNumber, totalPages }) => (
            <Text>
              {pageNumber} / {totalPages}
            </Text>
          )}
          fixed
        />
      </Page>
    </Document>
  );
};

export default StockSummaryByWarehousePdf;

// src/redux/productSizeSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeApiCall } from "../../makeAPICall";
import {
  PRODUCT_SIZE_CONTROLLER,
  PRODUCT_SIZE_PAGINATION_CONTROLLER,
} from "../../actionTypes";

export const getProductSizesWithPagination = createAsyncThunk(
  "productSize/getProductSizesWithPagination",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PRODUCT_SIZE_PAGINATION_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const saveProductSize = createAsyncThunk(
  "productSize/saveProductSize",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PRODUCT_SIZE_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const updateProductSize = createAsyncThunk(
  "productSize/updateProductSize",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "put",
        `/${PRODUCT_SIZE_CONTROLLER}/${obj.id}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getProductSizes = createAsyncThunk(
  "productSize/getProductSizes",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${PRODUCT_SIZE_CONTROLLER}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const getProductSize = createAsyncThunk(
  "productSize/getProductSize",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${PRODUCT_SIZE_CONTROLLER}/${id}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const deleteProductSize = createAsyncThunk(
  "productSize/deleteProductSize",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "delete",
        `/${PRODUCT_SIZE_CONTROLLER}/${id}`,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
const productSizeSlice = createSlice({
  name: "productSize",
  initialState: {
    productSizes: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductSizesWithPagination.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProductSizesWithPagination.fulfilled, (state, action) => {
        state.productSizes = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getProductSizesWithPagination.rejected, (state, action) => {
        state.productSizes = [];
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(saveProductSize.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveProductSize.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(saveProductSize.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getProductSizes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProductSizes.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.productSizes =
          data &&
          data.length > 0 &&
          data.map((x) => {
            return {
              label: x.name,
              value: x.id,
            };
          });
        state.loading = false;
        state.error = null;
      })
      .addCase(getProductSizes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {} = productSizeSlice.actions;
export default productSizeSlice.reducer;

import { Add, Delete } from "@mui/icons-material";
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { NumericFormat } from "react-number-format";
import { Util } from "../../../util/Util";
import { ProductUnitEnum } from "../../../actionTypes";
const util = new Util();

function subtotal(items) {
  return items
    ? items
        .map(({ sub_total }) => sub_total)
        .reduce((sum, i) => parseFloat(sum) + parseFloat(i), 0)
        .toFixed(2)
    : 0;
}

function getDiscount(items) {
  return items
    ? items
        .map(({ discount_amount }) => discount_amount)
        .reduce((sum, i) => parseFloat(sum) + parseFloat(i), 0)
        .toFixed(2)
    : 0;
}
function getTax(items) {
  return items
    ? items
        .map(({ tax_amount }) => tax_amount)
        .reduce((sum, i) => parseFloat(sum) + parseFloat(i), 0)
        .toFixed(2)
    : 0;
}
export const makeGramToVori = (gram) => {
  const { vori, ana, roti, point } = util.convertToVori(gram);
  return (
    vori + " vori, " + ana + " ana," + roti + " roti, " + point + " point."
  );
};
export const makeGramToVoriAmt = (gram) => {
  const { vori, ana, roti, point } = util.convertToVori(gram);
  return { vori, ana, roti, point };
};
export default function SpanningTable({
  details,
  handleAdd,
  handleDelete,
  handleInputChange,
  handleSelectChange,
  handleOptionChange,
  handleAutoCompleteSelectChange,
  fetchProduct,
  loading,
  products,
  unitList,
  rackList,
}) {
  const grandTotal = subtotal(details);
  const totalDiscount = getDiscount(details);
  const totalTax = getTax(details);

  const toRemove =
    details && details.length > 0
      ? details.map((x) => {
          return x.id.toString();
        })
      : [];
  var filtedItems = products.filter((item) => !toRemove.includes(item.ItemID));

  const canAdd = (index) => {
    return (
      details[index].product_id !== "" &&
      details[index].purchase_unit_id !== "" &&
      details[index].rack_id !== "" &&
      details[index].quantity > 0
    );
  };
  
  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow style={{ width: "100%", backgroundColor: "#eef2ff" }}>
            <TableCell style={{ width: "15%" }}>Product</TableCell>
            <TableCell style={{ width: "10%" }}>Purchase Unit</TableCell>
            <TableCell style={{ width: "10%" }}>Units</TableCell>
            <TableCell style={{ width: "10%" }}>Qty.</TableCell>
            <TableCell style={{ width: "10%" }}>Unit Price</TableCell>
            <TableCell style={{ width: "10%" }}>Discount Amt.</TableCell>
            {/* <TableCell style={{ width: "10%" }}>Tax Amt.</TableCell> */}
            <TableCell style={{ width: "10%" }}>Sub Total</TableCell>
            <TableCell style={{ width: "15%" }}>Rack</TableCell>
            <TableCell style={{ width: "10%" }} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {details &&
            details.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <div className="flex items-center justify-between">
                    <div className="mt-8 mb-8" style={{ flex: "1" }}>
                      <Autocomplete
                        freeSolo={true}
                        options={products.map((x) => ({
                          product_id: x.id || "",
                          product_name:
                            x.product_name + " - " + x.product_code + " - " + x.weight || "",
                          product_code: x.product_code || "",
                          purchase_unit_name: x.purchase_unit_name || "",
                          purchase_unit_id: x.purchase_unit_id || "",
                          weight_in_vori: x.weight_in_vori || "",
                          weight_in_ana: x.weight_in_ana || "",
                          weight_in_roti: x.weight_in_roti || "",
                          weight_in_point: x.weight_in_point || "",
                          measurement_size: x.measurement_size || "",
                          weight: x.weight || "",
                        }))}
                        className="mb-4"
                        loading={loading}
                        getOptionLabel={(option) => option.product_name || ""}
                        // onInputChange={(event, newInputValue) => {
                        //   fetchProduct(newInputValue);
                        // }}
                        value={{
                          product_id: row.product_id,
                          product_name: row.product_name,
                          product_code: row.product_code,
                          purchase_unit_name: row.purchase_unit_name,
                          purchase_unit_id: row.purchase_unit_id,
                          weight_in_vori: row.weight_in_vori,
                          weight_in_ana: row.weight_in_ana,
                          weight_in_roti: row.weight_in_roti,
                          weight_in_point: row.weight_in_point,
                          measurement_size: row.measurement_size,
                          weight: row.weight,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search Product..."
                            variant="standard"
                            fullWidth
                            error={row.product_id === ""}
                            id="product_id"
                            name="product_id"
                            onChange={(event) =>
                              handleInputChange(index, event)
                            }
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                        onChange={(event, selectedValue) => {
                          handleAutoCompleteSelectChange(
                            index,
                            selectedValue,
                            "product_id"
                          );
                        }}
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <Select
                    required
                    label="Unit"
                    fullWidth
                    variant="standard"
                    id="purchase_unit_id"
                    name="purchase_unit_id"
                    value={`${row.purchase_unit_id}`}
                    error={row.purchase_unit_id === ""}
                    onChange={(event) => handleSelectChange(event, index)}
                    displayEmpty
                    disabled
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                  >
                    {unitList &&
                      unitList.map((unit) => (
                        <MenuItem key={unit.value} value={unit.value}>
                          {unit.label}
                        </MenuItem>
                      ))}
                  </Select>
                  {/* <Typography>{row.purchase_unit_name}</Typography> */}
                </TableCell>
                <TableCell>
                  {row && row.purchase_unit_id === ProductUnitEnum.Vori && (
                    <>
                      <label className="w-full font-semibold text-xs flex">
                        {row.weight_in_vori} vori,
                        {row.weight_in_ana} ana,
                        {row.weight_in_roti} roti,
                        {row.weight_in_point} point
                      </label>
                      /
                      <label className="w-full font-semibold text-xs">
                        {util.convertToGram(
                          row.weight_in_vori,
                          row.weight_in_ana,
                          row.weight_in_roti,
                          row.weight_in_point
                        )}
                        &nbsp; grams
                      </label>
                    </>
                  )}
                  {row && row.purchase_unit_id === ProductUnitEnum.Gram && (
                    <>
                      <label className="w-full font-semibold text-xs">
                        {makeGramToVori(row.measurement_size)}
                      </label>
                      /
                      <label className="w-full font-semibold text-xs flex">
                        {row.measurement_size} grams
                      </label>
                    </>
                  )}
                </TableCell>
                <TableCell>
                  <TextField
                    error={row.quantity <= 0}
                    type="number"
                    id="quantity"
                    name="quantity"
                    value={row.quantity}
                    onChange={(event) => handleInputChange(index, event)}
                    variant="standard"
                    required
                    fullWidth
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    id="unit_purchase_price"
                    name="unit_purchase_price"
                    value={row.unit_purchase_price}
                    onChange={(event) => handleInputChange(index, event)}
                    variant="standard"
                    fullWidth
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    id="discount_amount"
                    name="discount_amount"
                    value={row.discount_amount}
                    onChange={(event) => handleInputChange(index, event)}
                    variant="standard"
                    fullWidth
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                  />
                </TableCell>
                {/* <TableCell align="right">
                  <TextField
                    type="number"
                    id="tax_amount"
                    name="tax_amount"
                    value={row.tax_amount}
                    onChange={(event) => handleInputChange(index, event)}
                    variant="standard"
                    fullWidth
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                  />
                </TableCell> */}
                <TableCell align="right">
                  <TextField
                    type="number"
                    id="sub_total"
                    name="sub_total"
                    value={row.sub_total}
                    onChange={(event) => handleInputChange(index, event)}
                    variant="standard"
                    fullWidth
                    inputProps={{ readOnly: true }}
                  />
                </TableCell>
                <TableCell>
                  {/* <FormControl fullWidth margin="normal">
            <InputLabel>Rack</InputLabel>
            <Select
              name="rack_id"
              label="Rack"
              value={form.rack_id}
              onChange={handleSelect}
              error={form.rack_id === ""}
            >
              {rackList &&
                rackList.length > 0 &&
                rackList.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select> */}

                  <Select
                    required
                    label="Rack"
                    fullWidth
                    variant="standard"
                    id="rack_id"
                    name="rack_id"
                    value={`${row.rack_id}`}
                    error={row.rack_id === ""}
                    onChange={(event) => handleSelectChange(event, index)}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                  >
                    {rackList &&
                      rackList.map((unit) => (
                        <MenuItem key={unit.value} value={unit.value}>
                          {unit.label}
                        </MenuItem>
                      ))}
                  </Select>
                  {/* </FormControl> */}
                </TableCell>
                <TableCell align="center">
                  <div className="flex flex-1 justify-center">
                    {details.length !== 1 && (
                      <div className="mt-8 mb-8">
                        <Tooltip title="Delete" placement="top">
                          <IconButton
                            onClick={(ev) => {
                              ev.stopPropagation();
                              handleDelete(index);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}

                    {details.length - 1 == index && canAdd(index) && (
                      <div className="mt-8 mb-8">
                        <Tooltip title="Add" placement="top">
                          <IconButton
                            style={{ padding: "5px !important" }}
                            onClick={(ev) => {
                              ev.stopPropagation();
                              handleAdd();
                            }}
                          >
                            <Add />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          <TableRow key="Total">
            <TableCell colSpan={5} sx={{ fontWeight: 500 }}>
              Total
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: 500 }}>
              <NumericFormat
                value={isNaN(totalDiscount) ? 0 : totalDiscount}
                displayType={"text"}
                thousandSeparator={true}
                thousandsGroupStyle="lakh"
              />
            </TableCell>
            {/* <TableCell align="right" sx={{ fontWeight: 500 }}>
              <NumericFormat
                value={isNaN(totalTax) ? 0 : totalTax}
                displayType={"text"}
                thousandSeparator={true}
                thousandsGroupStyle="lakh"
              />
            </TableCell> */}
            <TableCell align="right" sx={{ fontWeight: 500 }}>
              <NumericFormat
                value={isNaN(grandTotal) ? 0 : grandTotal}
                displayType={"text"}
                thousandSeparator={true}
                thousandsGroupStyle="lakh"
              />
            </TableCell>
            <TableCell colSpan={2}></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}

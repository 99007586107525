import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Autocomplete,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  createStockTransfer,
  getDataForStockTransferByProductId,
  getProduct,
} from "../../../redux/product/productSlice";
import useForm from "../../../hooks/useForm";
import { getWirehouses } from "../../../redux/wirehouse/wirehouseSlice";
import { getRacksByWirehouseId } from "../../../redux/rack/rackSlice";
import { getCustomersByName } from "../../../redux/customer/customerSlice";
import CreateCustomer from "../../customer/create-customer/CreateCustomer";
import { useLocation } from "react-router-dom";
import { getBatchByProductAndRack } from "../../../redux/purchase/purchaseSlice";
import toast from "react-hot-toast";
import moment from "moment";
import {
  getSellByBatchNo,
  getSellByBatchNoAndProduct,
  getSellByProduct,
  saveSell,
} from "../../../redux/sell/sellSlice";
import { saveSellReturn } from "../../../redux/sell-return/sellReturnSlice";
import _ from "../../../util/lodash/lodash";
import { ProductUnitEnum } from "../../../actionTypes";
import { makeGramToVori } from "../../purchase/create-purchase/ItemTable";

import { Util } from "../../../util/Util";
import SellReportPdf from "../../reports/_components/sell-report/SellReportPdf";
import {
  getCompany,
  getCompanyTerms,
} from "../../../redux/companyOld/companySlice";
import { printSellReport } from "../../../redux/reports/reportSlice";
import { pdf } from "@react-pdf/renderer";
import { getCompanyForReport } from "../../../redux/company/companySlice";
import { useNavigate } from "react-router-dom";

const util = new Util();

const actionList = [
  { label: "Direct Sell", value: 1 },
  { label: "Advance Sell", value: 2 },
  { label: "Baki Sell", value: 3 },
  { label: "Sell Return", value: 4 },
  { label: "Stock Transfer", value: 5 },
  { label: "View Stock", value: 6 },
  { label: "Print Barcode", value: 7 },
  { label: "Purchase Return", value: 8 },
];

const defaultForm = {
  actionType: null,
  product_id: "",
  wirehouse_id: "",
  rack_id: "",
  batch_no: "",
  batchList: [],
  customer_id: "",
  paid_amount: 0,
  total_amount: 0,
  quantity: 1,
  return_batch_number: "",
  return_quantity: "",
};
const ScanProduct = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data } = state; //{ data: 330 }; //state;
  const dispatch = useDispatch();
  const { form, resetForm, handleChange, setForm } = useForm(defaultForm);
  const [modifiedString, setModifiedString] = useState("");
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const wirehouseList = useSelector((state) => state.wirehouse.wirehouses);
  const rackList = useSelector((state) => state.rack.racks);
  const customerList = useSelector((state) => state.customer.searchedCustomers);
  //   const [customerList, setCustomerList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false); // State to control the dialog
  const [newCustomer, setNewCustomer] = useState(""); // State to hold newly created customer
  const [batchList, setBatchList] = useState("");
  const searchedSell = useSelector((state) => state.sell.searchedSell);
  const searchedStocks = useSelector((state) => state.product.searchedStocks);
  const [targetRackList, setTargetRackList] = useState();

  const companyTerms = useSelector((state) => state.company.companyTerms);
  const company = useSelector((state) => state.company.companyReport);

  useEffect(() => {
    if (searchedSell) {
      const upForm = {
        ...form,
        SellItemDetails: searchedSell.ItemDetails,
      };
      setForm(upForm);
    }
  }, [searchedSell]);

  useEffect(() => {
    if (searchedStocks && searchedStocks.length > 0) {
      // console.log('searchedSell: ', searchedSell)
      const upForm = {
        ...form,
        StockItemDetails: searchedStocks,
        SellItemDetails: (searchedSell && searchedSell.ItemDetails) || [],
      };
      setForm(upForm);
    }
  }, [searchedStocks]);
  useEffect(() => {
    const fetchData = async (id) => {
      const response = await dispatch(getProduct(id));
      const data =
        (await response) && response.payload && response.payload.data;
      setProduct(data);
    };

    // const mstring = data && data.replace(/Shift/g, " ");

    // const id = mstring && mstring.length > 0 && mstring.split(",")[0];
    // console.log('data:', data)
    // console.log('ms:', mstring)
    console.log("data", data);
    const id = parseInt(data || 0);
    // console.log('id: ', id)
    fetchData(id);
    dispatch(getWirehouses());

    dispatch(getCompanyTerms());

    dispatch(getCompanyForReport());

    dispatch(
      getSellByProduct({
        batch_number: form.return_batch_number,
        product_id: id,
      })
    );
    dispatch(getDataForStockTransferByProductId({ product_id: id }));
    setModifiedString("");
  }, [data]);

  const handleDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleSelect = async (e) => {
    const { name, value } = e.target;
    if (name === "actionType") {
      if (value == 6) {
        navigate("/view-stock", { state: { id: product.id } });
      } else if (value == 7) {
        navigate("/print-barcode", { state: { id: product.id } });
      } else if (value == 8) {
        navigate("/purchase-return", {
          state: { batch_number: product.batch_number },
        });
      }
      const updateForm = {
        ...form,
        [name]: value,
      };
      setForm({ ...updateForm });
    } else if (name === "rack_id") {
      const updateForm = {
        ...form,
        [name]: value,
      };
      const response = await dispatch(
        getBatchByProductAndRack({
          product_id: product.id,
          wirehouse_id: (form && form.wirehouse_id) || "",
          rack_id: e.target.value,
        })
      )
        .then((res) => {
          const data =
            (res && res.payload && res.payload.data && res.payload.data.rows) ||
            [];
          const btchList = data.map((x) => {
            return {
              label: x.batch_no,
              value: x.batch_no,
              total_in: x.total_in,
              total_out: x.total_out,
              total_quantity: x.total_quantity,
            };
          });
          setBatchList(btchList);
        })
        .catch((e) => {
          console.log(e);
          toast.error(e);
        });
      setForm({ ...updateForm });
    } else if (name === "wirehouse_id") {
      const updateForm = {
        ...form,
        [name]: value,
        rack_id: "",
      };
      setForm({ ...updateForm });
      dispatch(getRacksByWirehouseId(value));
    } else {
      const updateForm = {
        ...form,
        [name]: value,
      };
      setForm({ ...updateForm });
    }
  };

  const handleInputChangeCustomer = (event) => {
    if (event.target.name === "customer_id") {
      const upForm = {
        ...form,
        customer_name: event.target.value,
        customer_id: "",
      };

      if (event.target.value.length > 0) {
        dispatch(getCustomersByName({ name: event.target.value }));
      }
      setForm(upForm);
    }
  };

  const handleCustomerOptionChange = (event, newValue) => {
    setSelectedOption(newValue);
  };
  const handleAutoCompleteSelectChangeForCustomer = (value) => {
    const upForm = {
      ...form,
      customer_id: (value && value.customer_id) || "",
      customer_name: (value && value.customer_name) || "",
    };
    setForm(upForm);
  };
  const setCustomer = (data) => {
    const { id, full_name } = data;
    const updateForm = {
      ...form,
      customer_id: id,
      customer_name: full_name,
    };
    setForm(updateForm);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      form.actionType === 1 ||
      form.actionType === 2 ||
      form.actionType === 3
    ) {
      const updatedForm = {
        ...form,
        paid_amount:
          form && form.actionType === 1 ? form.total_amount : form.paid_amount,
        sale_date: moment(new Date()).format("YYYY-MM-DD"),
        delivery_date: moment(new Date()).format("YYYY-MM-DD"),
        total_amount: form.total_amount,
        payment_status_id:
          form.actionType === 1
            ? "305b18ca-6ba7-48e9-9dce-8a852fbb04e8"
            : form.actionType === 2
            ? "94c653b0-ef31-4335-a0d4-dd14c7a8e797"
            : "53ba9912-2dcb-42e8-bc29-393963bb40f8",
        delivery_status_id: 1,
        paid_through: 1,

        SellChilds: [
          {
            product_id: product.id,
            sale_unit_id: product.sale_unit_id,
            rack_id: form.rack_id,
            batch_no: form.batch_no,
            quantity: form.quantity,
            unit_sell_price: parseFloat(
              parseFloat(form.total_amount) / parseFloat(form.quantity)
            ).toFixed(2),
            sub_total: parseFloat(form.total_amount).toFixed(2),
          },
        ],
      };
      const res = dispatch(saveSell(updatedForm))
        .then((response) => {
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 201
          ) {
            toast.success(
              response && response.payload && response.payload.message
            );
            dispatch(
              printSellReport({
                sales_no:
                  response &&
                  response.payload &&
                  response.payload.data &&
                  response.payload.data.sales_no,
              })
            )
              .then((res) => {
                const dt = res;
                const data = (dt && dt.payload && dt.payload.data) || null;
                showPDF(data, response.payload.data.sales_no);
                window.location.reload();
              })
              .catch((e) => {
                console.log(e);
                toast.error(e);
              });
            resetForm();
            window.location.reload();
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          toast.error(error);
        });
    } else if (form.actionType === 4) {
    } else if (form.actionType === 5) {
    }
  };
  const showPDF = async (data, sales_no) => {
    const doc = (
      <SellReportPdf data={data} terms={companyTerms} company={company} />
    );

    const blob = await pdf(doc).toBlob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Sell_Report_${sales_no}.pdf`;
    link.click();

    //window.location.reload();
  };
  // const handleSearchSell = () => {
  //   dispatch(
  //     getSellByProduct({
  //       batch_number: form.return_batch_number,
  //       product_id: product.id,
  //     })
  //   );
  // };
  const handleReturnQuantityChange = (e, index) => {
    const { name, value } = e.target;
    const updateForm = JSON.parse(JSON.stringify(form.SellItemDetails));

    if (name === "returned_quantity") {
      if (
        Number(value) + Number(updateForm[index].total_in) >
        updateForm[index].quantity
      ) {
        updateForm[index].error =
          "Returned quantity cannot exceed selld quantity";
      } else {
        updateForm[index].error = null;
      }
      updateForm[index].returned_quantity = Number(value);
    }

    setForm({ ...form, SellItemDetails: updateForm });
  };
  const handleReturn = async (e, item) => {
    e.preventDefault();
    const updateForm = {
      ...form,
      batch_number: form.return_batch_number,
      wirehouse_id: item.wirehouse_id,
      id: item.sales_id,
      SellReturnChilds: [
        {
          product_id: item.product_id,
          quantity: item.quantity,
          returned_quantity: item.returned_quantity,
          amount: item.amount,
          sale_item_id: item.id,
        },
      ],
      ItemDetails: form && form.SellItemDetails,
    };
    try {
      const res = await dispatch(saveSellReturn(updateForm));
      if (res && res.payload && res.payload.statusCode === 201) {
        toast.success("Returned Successfully");
        //dispatch(getSellByBatchNo({ batch_number: form.batch_number }));
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleStockSelectChange = (event, index) => {
    const updateForm = JSON.parse(JSON.stringify(form.StockItemDetails));
    if (event.target.name === "target_wirehouse_id") {
      updateForm[index].target_wirehouse_id = event.target.value;
      dispatch(getRacksByWirehouseId(event.target.value))
        .then((res) => {
          const data = (res && res.payload && res.payload.data) || [];
          const comboData =
            data &&
            data.length > 0 &&
            data.map((x) => {
              return {
                ...x,
                label: x.rack_name,
                value: x.id,
              };
            });
          setTargetRackList(comboData);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (event.target.name === "target_rack_id") {
      updateForm[index].target_rack_id = event.target.value;
    }
    setForm(
      _.set(
        { ...form },
        "StockItemDetails",
        updateForm,
        ...form.StockItemDetails
      )
    );
  };
  const handleStockInputChange = (index, event) => {
    const updateForm = JSON.parse(JSON.stringify(form.StockItemDetails));
    if (event.target.name === "transfer_quantity") {
      if (
        Number(event.target.value) > Number(updateForm[index].total_quantity)
      ) {
        updateForm[index].error =
          "Transfer quantity cannot exceed total quantity";
      } else {
        updateForm[index].error = null;
      }
      updateForm[index].transfer_quantity = Number(event.target.value);
      //updateForm[index].transfer_quantity = event.target.value;
    }
    setForm(
      _.set(
        { ...form },
        "StockItemDetails",
        updateForm,
        ...form.StockItemDetails
      )
    );
  };

  const handleTransfer = async (e, item, index) => {
    const obj = {
      batch_number: item.batch_number,
      wirehouse_id: item.wirehouse_id,
      rack_id: item.rack_id,
      transfer_wirehouse_id: item.target_wirehouse_id,
      transfer_rack_id: item.target_rack_id,
      product_id: item.product_id,
      transfer_quantity: item.transfer_quantity,
    };
    const res = await dispatch(createStockTransfer(obj))
      .then((response) => {
        if (
          response &&
          response.payload &&
          response.payload.statusCode === 200
        ) {
          toast.success(
            response && response.payload && response.payload.message
          );
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        toast.error(error);
      });

    //await dispatch(getDataForStockTransfer(form));
  };

  const getMeasurement = (msize, unitName, selectedOption) => {
    let val = "";
    if (selectedOption && selectedOption.unit_id === ProductUnitEnum.Vori) {
      let sonaton =
        selectedOption.weight_in_vori +
        " vori," +
        selectedOption.weight_in_ana +
        " ana," +
        selectedOption.weight_in_roti +
        " roti," +
        selectedOption.weight_in_point +
        " point.";

      val += sonaton;
      val += "/";
      val += util.convertToGram(
        selectedOption.weight_in_vori,
        selectedOption.weight_in_ana,
        selectedOption.weight_in_roti,
        selectedOption.weight_in_point
      );
      val += " grams.";
      //val += selectedOption.unit_name;
    } else if (
      selectedOption &&
      selectedOption.unit_id === ProductUnitEnum.Gram
    ) {
      val += makeGramToVori(selectedOption.measurement_size);
      val += "/";
      let digital = selectedOption.measurement_size + " grams.";
      val += digital;
    } else {
      val += msize + " " + unitName;
    }

    return val;
  };

  return (
    <div className="p-4">
      <h4>
        Scanned Product: {product && product.product_name}
        {", "}
        {getMeasurement(
          product && product.measurement_size,
          product && product.unit_name,
          product
        )}{" "}
        (SL:{product && product.serial_no}) -
        {form &&
        form.StockItemDetails &&
        form.StockItemDetails.length > 0 &&
        form.StockItemDetails[0].total_quantity > 0 ? (
          <span className="text-green-600"> In Stock</span>
        ) : (
          <span className="text-red-600"> Out of Stock</span>
        )}
      </h4>
      <FormControl fullWidth margin="normal" variant="outlined">
        <InputLabel>Action Type</InputLabel>
        <Select
          value={form.actionType}
          onChange={handleSelect}
          label="Product Category"
          name="actionType"
          error={form.actionType === ""}
        >
          {actionList &&
            actionList.length > 0 &&
            actionList.map((item) => (
              <MenuItem value={item.value}>{item.label}</MenuItem>
            ))}
        </Select>
      </FormControl>

      {(form.actionType === 1 ||
        form.actionType === 2 ||
        form.actionType === 3) && (
        <div className="flex flex-col">
          <div className="w-full flex flex-col md:flex-row items-center gap-4">
            <FormControl fullWidth margin="normal">
              <InputLabel>Wirehouse</InputLabel>
              <Select
                name="wirehouse_id"
                label="Wirehouse"
                value={form.wirehouse_id}
                onChange={handleSelect}
                error={form.wirehouse_id === ""}
              >
                {wirehouseList &&
                  wirehouseList.length > 0 &&
                  wirehouseList.map((item) => (
                    <MenuItem value={item.value} key={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Rack</InputLabel>
              <Select
                value={form.rack_id}
                label="Rack"
                name="rack_id"
                onChange={handleSelect}
                error={form.rack_id === ""}
              >
                {rackList &&
                  rackList.length > 0 &&
                  rackList.map((item) => (
                    <MenuItem value={item.value} key={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>

          <div className="w-full flex flex-col md:flex-row items-center gap-4">
            <div className="w-full flex gap-4 items-center">
              <div className="w-full flex-[.9]">
                <Autocomplete
                  freeSolo={true}
                  options={
                    Array.isArray(customerList) && customerList.length > 0
                      ? customerList.map((x) => ({
                          customer_id: x.value || "",
                          customer_name: x.label || "",
                        }))
                      : []
                  }
                  loading={loading}
                  getOptionLabel={(option) =>
                    (option && option.customer_name) || ""
                  }
                  // onInputChange={(event, newInputValue) => {
                  //   fetchProduct(newInputValue);
                  // }}
                  value={{
                    customer_id: form.customer_id,
                    customer_name: form.customer_name,
                  }}
                  onChange={(event, selectedValue) => {
                    handleAutoCompleteSelectChangeForCustomer(selectedValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Customer..."
                      fullWidth
                      error={form.customer_id === ""}
                      id="customer_id"
                      name="customer_id"
                      onChange={handleInputChangeCustomer}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </div>
              <div className="flex-[.1]">
                <Button variant="outlined" onClick={handleDialog}>
                  Add
                </Button>
              </div>
            </div>
            <FormControl fullWidth margin="normal">
              <InputLabel>Batch</InputLabel>
              <Select
                required
                label="Batch No"
                fullWidth
                id="batch_no"
                name="batch_no"
                value={form.batch_no}
                error={form.batch_no === ""}
                onChange={handleSelect}
              >
                {batchList &&
                  batchList.map((btch) => (
                    <MenuItem key={btch.value} value={btch.value}>
                      {btch.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="w-full flex flex-col md:flex-row items-center gap-4">
            <TextField
              label="Quantity"
              name="quantity"
              type="number"
              value={form.quantity}
              onChange={handleChange}
              error={form.quantity == 0}
              fullWidth
              margin="normal"
            />
          </div>
          <div className="w-full flex flex-col md:flex-row items-center gap-4">
            <TextField
              label="Total Amount"
              name="total_amount"
              type="number"
              value={form.total_amount}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Paid Amount"
              name="paid_amount"
              type="number"
              value={form.paid_amount}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </div>
          <div>
            {product && product.canSell ? (
              <Button
                color="secondary"
                variant="contained"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            ) : (
              <p className="text-lg font-bold text-red-600">
                This product is already sold.
              </p>
            )}
          </div>
        </div>
      )}

      {form.actionType === 4 && (
        <div className="flex flex-col">
          {/* <TextField
            label="Sell No"
            name="return_batch_number"
            value={form.return_batch_number || ""}
            onChange={handleChange}
            required
            error={form.return_batch_number === ""}
            placeholder="Enter Batch No to Search..."
            fullWidth
            margin="normal"
          /> */}
          {/* <div>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSearchSell}
              disabled={form && form.return_batch_number === ""}
            >
              view sell
            </Button>
          </div> */}

          {form && form.SellItemDetails && form.SellItemDetails.length > 0 ? (
            <TableContainer component={Paper}>
              <Table size="medium">
                <TableHead>
                  <TableRow>
                    {/* Define table header cells */}
                    <TableCell>Sell No</TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell>Unit</TableCell>
                    <TableCell>Rack</TableCell>
                    <TableCell>Sold Quantity</TableCell>
                    <TableCell>Return Quantity</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {form &&
                    form.SellItemDetails &&
                    form.SellItemDetails.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.batch_no || ""}</TableCell>
                        <TableCell>{item.product_name || ""}</TableCell>
                        <TableCell>{item.unit_name || ""}</TableCell>
                        <TableCell>{item.rack_name || 0}</TableCell>
                        <TableCell>{item.quantity || 0}</TableCell>
                        <TableCell sx={{ width: "250px" }}>
                          {/* {item.total_in > 0 && ( */}
                          <Typography variant="caption">
                            Already Returned Quantity: {item.total_in}
                          </Typography>
                          {/* )} */}
                          <TextField
                            label="Return Quantity"
                            name="returned_quantity"
                            variant="standard"
                            type="number"
                            value={item.returned_quantity}
                            onChange={(e) =>
                              handleReturnQuantityChange(e, index)
                            }
                            required
                            error={item.error || item.returned_quantity <= 0}
                            helperText={item.error}
                            placeholder="Enter return quantity"
                            fullWidth
                            margin="normal"
                          />
                        </TableCell>
                        <TableCell>
                          {item.can_return ? (
                            <Button
                              variant="contained"
                              size="small"
                              disabled={
                                item.error || item.returned_quantity <= 0
                              }
                              onClick={(e) => handleReturn(e, item)}
                            >
                              Return
                            </Button>
                          ) : (
                            <Typography variant="caption">
                              Already returned full quantity
                            </Typography>
                          )}
                        </TableCell>
                        {/* Add more cells if needed */}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="h4">No Sell Found</Typography>
          )}
        </div>
      )}

      {form.actionType === 5 &&
        (form && form.StockItemDetails && form.StockItemDetails.length > 0 ? (
          <TableContainer component={Paper}>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  {/* Define table header cells */}
                  <TableCell>Batch</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell>Wirehouse</TableCell>
                  <TableCell>Rack</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Target Wirehouse</TableCell>
                  <TableCell>Target Rack</TableCell>
                  <TableCell>Target Quantity</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {form &&
                  form.StockItemDetails.length > 0 &&
                  form.StockItemDetails.map((item, index) => (
                    <TableRow key={index}>
                      {/* Render table items based on fetched data */}
                      <TableCell>{item.batch_number || ""}</TableCell>
                      <TableCell>{item.product_name || ""}</TableCell>
                      <TableCell>{item.wirehouse_name || ""}</TableCell>
                      <TableCell>{item.rack_name || 0}</TableCell>
                      <TableCell>{item.total_quantity || 0}</TableCell>
                      <TableCell>
                        {" "}
                        <Select
                          required
                          label="Target Wirehouse"
                          fullWidth
                          variant="standard"
                          id="target_wirehouse_id"
                          name="target_wirehouse_id"
                          value={`${item.target_wirehouse_id}`}
                          error={!item.target_wirehouse_id}
                          onChange={(event) =>
                            handleStockSelectChange(event, index)
                          }
                          displayEmpty
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                        >
                          {wirehouseList &&
                            wirehouseList.map((unit) => (
                              <MenuItem key={unit.value} value={unit.value}>
                                {unit.label}
                              </MenuItem>
                            ))}
                        </Select>
                        {/* </FormControl> */}
                      </TableCell>
                      <TableCell>
                        {" "}
                        <Select
                          required
                          label="Target Rack"
                          fullWidth
                          variant="standard"
                          id="target_rack_id"
                          name="target_rack_id"
                          value={`${item.target_rack_id}`}
                          error={!item.target_rack_id}
                          onChange={(event) =>
                            handleStockSelectChange(event, index)
                          }
                          displayEmpty
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                        >
                          {targetRackList &&
                            targetRackList.map((unit) => (
                              <MenuItem key={unit.value} value={unit.value}>
                                {unit.label}
                              </MenuItem>
                            ))}
                        </Select>
                        {/* </FormControl> */}
                      </TableCell>
                      <TableCell sx={{ width: "200px" }}>
                        <TextField
                          label="Target Quantity"
                          name="transfer_quantity"
                          type="number"
                          error={item.error || !item.transfer_quantity}
                          value={item.transfer_quantity}
                          onChange={(event) =>
                            handleStockInputChange(index, event)
                          }
                          helperText={item.error}
                          fullWidth
                          margin="normal"
                          InputProps={{
                            inputProps: {
                              min: 0,
                              max: item.total_quantity,
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          size="small"
                          disabled={
                            !item.target_wirehouse_id ||
                            !item.target_rack_id ||
                            !item.transfer_quantity
                          }
                          onClick={(e) => handleTransfer(e, item, index)}
                        >
                          Transfer
                        </Button>
                      </TableCell>
                      {/* Add more cells if needed */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="h4">No Stock Found</Typography>
        ))}

      <Dialog open={openDialog} onClose={handleDialog}>
        <DialogTitle>Create New Customer</DialogTitle>
        <DialogContent>
          <CreateCustomer isFromSell={true} setCustomer={setCustomer} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleDialog}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ScanProduct;
